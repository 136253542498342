import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { AnimatePresence, MotionConfig, motion } from 'framer-motion'
import Swal from 'sweetalert2'

const Quiero_radiografia = () => {

    const formRef = useRef(null);
    const resetFormHTML = () => {
        formRef.current.reset();
        // También debes resetear el estado de React si es necesario
        setFormData({
            nombre: '',
            telefono: '',
            email: '',
            api: 1,
            id_area: 8
        });
    };










    // Setea los valores de formulario a enviar
    const [formData, setFormData] = useState({
        nombre: '',
        telefono: '',
        email: '',
        api: 1,
        id_area: 8
    });

    // Evento para guardar el valor en FormData
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    // // submit de formulario de landing
    const handleSubmit = (event) => {
        event.preventDefault();

        Swal.fire({
            title: "¿Su datos son correctos?",
            text: "Antes de enviar revise que sus datos sean completamente correctos.",
            icon: "info",
            showCancelButton: true,
            // confirmButtonColor: "#3085d6",
            // cancelButtonColor: "#d33",
            confirmButtonText: "Confirmar"
        }).then(
            (result) => {
                if (result.isConfirmed) {

                    axios.post('https://bimo-lab.com/nuevo_checkup/api/landings_api.php', formData)
                        .then((response) => {
                            console.log(response);

                            // Una vez confirmado que back a guardado todo
                            Swal.fire({
                                title: "Solicitud enviada!",
                                text: "Nos contactaremos con usted lo más pronto posible.",
                                icon: "success"
                            });

                            // Resetea formulario
                            resetFormHTML();

                        })
                        .catch((error) => {
                            console.error('Hubo un error al enviar el formulario:', error);
                            // Aquí maneja el error. Por ejemplo: mostrar una alerta de error.
                            // Una vez confirmado que back a guardado todo
                            Swal.fire({
                                title: "Solicitud no enviada :(",
                                text: "Hubo un error, no dudes en contactarnos.",
                                icon: "error"
                            });
                        });

                }
            });

        // Toast.success()


    };


    return (
        <>
            {/* Titulo de la pagina */}
            <title>BIMO | Política de Privacidad </title>
            {/* Loader */}
            {/* {isLoading && <Loader />} */}
            {/* Seccion Hero de la pagina */}
            <div className="bg-cover-page bg-serotonina" style={{ height: '30vh' }}>
                <div className='text-container'>
                    <h2 className='text-center text-light display-5'>¿Necesitas una radiografía?</h2>
                    {/* <h3 className='text-center text-pantone' style={{
                        fontSize: "1.25rem"
                    }}>Con equipos de alta resolución</h3> */}
                </div>
            </div>


            {/* Contenido de la paginas */}
            <section className='px-3'>
                <div className="container pb-5 pt-4">

                    <motion.div
                        layout
                        initial={{ y: -20, opacity: 0 }}
                        whileInView={{ y: 0, opacity: 1 }}
                        transition={{
                            ease: "linear",
                            duration: 0.2,
                            delay: 0.15 + (1 * 0.05)
                        }}>

                        <p className="text-center text-uppercase pb-3" style={{
                            color: "red"
                        }}>Promoción del mes</p>
                    </motion.div>


                    {/* <motion.div
                        layout
                        initial={{ y: -20, opacity: 0 }}
                        whileInView={{ y: 0, opacity: 1 }}
                        transition={{
                            ease: "linear",
                            duration: 0.2,
                            delay: 0.15 + (2 * 0.05)
                        }}>

                        <h1 className='text-center text-uppercase mb-5 fw-bold text-pantone-2'>
                            Radiografía
                            <span style={{
                                color: "red"
                            }}> $250.00 </span>
                            <span style={{
                                fontSize: "15px"
                            }}> pesos (una sola región).</span>
                        </h1>
                    </motion.div> */}


                    <div className="row">

                        <div className="col-12 col-md-6 text-center">
                            <motion.div
                                layout
                                initial={{ y: 20, opacity: 0 }}
                                whileInView={{ y: 0, opacity: 1 }}
                                transition={{
                                    ease: "linear",
                                    duration: 0.2,
                                    delay: 0.15 + (3 * 0.05)
                                }}>
                                <p>Nuestros estudios especializados son interpretados por médicos especialistas.</p>
                                <img src="assets/img/landing/radiografia_250-03_04_2024.webp" className="img-fluid shadow-sm rounded-3" alt="" />
                            </motion.div>
                        </div>

                        <div className="col-12 col-md-6 ">

                            <motion.div
                                layout
                                initial={{ y: 20, opacity: 0 }}
                                whileInView={{ y: 0, opacity: 1 }}
                                transition={{
                                    ease: "linear",
                                    duration: 0.2,
                                    delay: 0.15 + (3 * 0.05)
                                }}>
                                <p className='text-p fw-bold'>
                                    <i className="bi bi-send-fill me-2"></i>
                                    Estudio de rayos x con promoción (Agenda tu cita)
                                </p>
                            </motion.div>

                            <div className='text-center'>

                                <motion.div
                                    layout
                                    initial={{ y: 20, opacity: 0 }}
                                    whileInView={{ y: 0, opacity: 1 }}
                                    transition={{
                                        ease: "linear",
                                        duration: 0.2,
                                        delay: 0.15 + (3 * 0.05)
                                    }}>
                                    <p>Déjanos tu información aquí.</p>
                                </motion.div>

                            </div>
                            <form className="px-4" ref={formRef} id="formContacto" onSubmit={handleSubmit}>


                                <div className="row">
                                    <div className='mb-3 '>
                                        <motion.div
                                            layout
                                            initial={{ y: 20, opacity: 0 }}
                                            whileInView={{ y: 0, opacity: 1 }}
                                            transition={{
                                                ease: "linear",
                                                duration: 0.2,
                                                delay: 0.15 + (4 * 0.05)
                                            }}>
                                            {/* Nombred completo del paciente */}
                                            <label style={{ color: "rgb(000, 078, 89)" }} className='pb-2'>Nombre completo*</label>
                                            <input
                                                type="text"
                                                className='form-control rounded-0'
                                                placeholder='Nombre y apellidos'
                                                name="nombre"
                                                value={formData.nombre}
                                                required
                                                onChange={handleInputChange}
                                                style={{ height: '50px' }}
                                            />
                                        </motion.div>
                                    </div>
                                    <div className='mb-3 '>
                                        {/* Telefono */}
                                        <motion.div
                                            layout
                                            initial={{ y: 20, opacity: 0 }}
                                            whileInView={{ y: 0, opacity: 1 }}
                                            transition={{
                                                ease: "linear",
                                                duration: 0.2,
                                                delay: 0.15 + (5 * 0.05)
                                            }}>
                                            <label style={{ color: "rgb(000, 078, 89)" }} className='pb-2'>Número de teléfono de contacto*</label>
                                            <input
                                                type="number"
                                                className='form-control rounded-0'
                                                placeholder='Contacto vía WhatsApp'
                                                name="telefono"
                                                value={formData.telefono}
                                                required
                                                onChange={handleInputChange}
                                                style={{ height: '50px' }}
                                            />
                                        </motion.div>
                                    </div>
                                    <div className='mb-3'>
                                        {/* Email */}
                                        <motion.div
                                            layout
                                            initial={{ y: 20, opacity: 0 }}
                                            whileInView={{ y: 0, opacity: 1 }}
                                            transition={{
                                                ease: "linear",
                                                duration: 0.2,
                                                delay: 0.15 + (6 * 0.05)
                                            }}>
                                            <label style={{ color: "rgb(000, 078, 89)" }} className='pb-2'>Correo Electronico de contacto*</label>
                                            <input
                                                type="email"
                                                className='form-control rounded-0'
                                                placeholder='Correo'
                                                name="email"
                                                value={formData.email}
                                                required
                                                onChange={handleInputChange}
                                                style={{ height: '50px' }}
                                            />
                                        </motion.div>
                                    </div>
                                </div>

                                <div className='text-end'>
                                    <button
                                        type='submit'
                                        className='btn btn-primary fw-bold p-3'
                                        style={{
                                            fontSize: "13px",
                                            letterSpacing: "1px"
                                        }}
                                    >
                                        ENVIAR
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}


export default Quiero_radiografia