import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { AnimatePresence, MotionConfig, motion } from 'framer-motion'
import Swal from 'sweetalert2'

const Landings_areas = () => {


    const information = {
        '#Bimo': {
            titulo: 'Centro de Diagnóstico médico-preventivo',
            subtitulo: '',
            informacion: `
                    Nuestra especialidad: programas de <span style="font-size: 18px !important;font-weight:bold">EVALUACIÓN MÉDICA INTEGRAL</span>, diseñados a medida para cada persona.
                    Con un equipo apasionado y tecnología avanzada, trabajamos cada día para garantizar que tu experiencia con nosotros no
                    sea simplemente una visita médica, sino un momento de cuidado, atención y, sobre todo, entendimiento.
            `,
            imagen: 'assets/img/landing/bimo-landing-04-04-2024.webp',
            formulario_titulo: 'Agenda tu cita',
            id_area: '',
            clave: 'Bimo'
        },
        '#Laboratorio': {
            titulo: '¿Necesitas realizarte Análisis clínicos?',
            subtitulo: 'Diagnóstico Biomolecular',
            informacion: `
                Tu salud es lo más importante y queremos ayudarte a cuidarla.
                
                En BIMO laboratorio ofrecemos una amplia variedad de análisis clínicos que te permitirán conocer el estado de tu salud de manera precisa y confiable.
                
                Realizamos pruebas de sangre, orina, heces y más, para detectar cualquier posible alteración en tu organismo.
                No dejes pasar más tiempo y agenda tu cita para realizar tus análisis clínicos.
            `,
            imagen: 'assets/img/landing/análisis_clínicos-04-04-2024.webp',
            formulario_titulo: 'Somos un Laboratorio y Centro de Diagnóstico médico-preventivo. (Agenda tu cita)',
            id_area: 12,
            clave: 'Laboratorio de Análisis Clínicos'
        },
        '#Checkup-pediatrico': {
            titulo: 'Cuidando la salud de tus pequeños, con nuestro Check Up Pediátrico',
            subtitulo: '',
            informacion: `
                Paquete de exámenes clínicos y procedimientos de tecnología, con los que tu médico y tú podrán conocer el estado
                de salud de tus pequeños.
            `,
            imagen: 'assets/img/landing/checkup_pediatrico-04-04-2024.webp',
            formulario_titulo: 'Agenda tu cita',
            id_area: '',
            clave: 'Checkup Pediátrico'
        },
        '#Salud-ocupacional': {
            titulo: 'Examen clínico de ingreso laboral',
            subtitulo: '',
            informacion: `
                <span style="font-weight:bold">Los exámenes ingreso laboral evalúan el estado mental y físico de quien desea aplicar al cargo, </span>
                de manera que las actividades requeridas no impliquen ningún daño en la salud o riesgo para el bienestar del candidato. <br>
                En BIMO laboratorio ofrecemos una amplia variedad de análisis clínicos,
                <span style="font-weight:bold">exámenes ingreso laboral , que como ya sabemos actúan también como parte del filtro que utilizan las empresas antes de contratar personal para sus equipos de trabajo.</span>
            `,
            imagen: 'assets/img/landing/Salud_ocupacional-04_04_2024.webp',
            formulario_titulo: 'Agenda tu cita',
            id_area: '',
            clave: 'Salud Ocupacional'
        }
    }

    const pageinfo = information[window.location.hash];

    console.log(information, pageinfo, window.location.hash)


    const formRef = useRef(null);
    const resetFormHTML = () => {
        formRef.current.reset();
        // También debes resetear el estado de React si es necesario
        setFormData({
            nombre: '',
            telefono: '',
            email: '',
            api: 1,
            id_area: pageinfo.id_area,
            clave: pageinfo.clave,
        });
    };










    // Setea los valores de formulario a enviar
    const [formData, setFormData] = useState({
        nombre: '',
        telefono: '',
        email: '',
        api: 1,
        id_area: '',
        clave: pageinfo.clave,
    });

    // Evento para guardar el valor en FormData
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    // // submit de formulario de landing
    const handleSubmit = (event) => {
        event.preventDefault();

        Swal.fire({
            title: "¿Su datos son correctos?",
            text: "Antes de enviar revise que sus datos sean completamente correctos.",
            icon: "info",
            showCancelButton: true,
            // confirmButtonColor: "#3085d6",
            // cancelButtonColor: "#d33",
            confirmButtonText: "Confirmar"
        }).then(
            (result) => {
                if (result.isConfirmed) {

                    axios.post('https://bimo-lab.com/nuevo_checkup/api/landings_api.php', formData)
                        .then((response) => {
                            console.log(response);

                            // Una vez confirmado que back a guardado todo
                            Swal.fire({
                                title: "Solicitud enviada!",
                                text: "Nos contactaremos con usted lo más pronto posible.",
                                icon: "success"
                            });

                            // Resetea formulario
                            resetFormHTML();

                        })
                        .catch((error) => {
                            console.error('Hubo un error al enviar el formulario:', error);
                            // Aquí maneja el error. Por ejemplo: mostrar una alerta de error.
                            // Una vez confirmado que back a guardado todo
                            Swal.fire({
                                title: "Solicitud no enviada :(",
                                text: "Hubo un error, no dudes en contactarnos.",
                                icon: "error"
                            });
                        });

                }
            });

        // Toast.success()


    };


    return (
        <>
            {/* Titulo de la pagina */}
            <title>BIMO | Política de Privacidad </title>
            {/* Loader */}
            {/* {isLoading && <Loader />} */}
            {/* Seccion Hero de la pagina */}
            <div className="bg-cover-page bg-serotonina" style={{ height: '30vh' }}>
                <div className='text-container'>
                    <h2 className='text-center text-light display-5'>{pageinfo.titulo}</h2>
                    <h3 className='text-center text-pantone' style={{
                        fontSize: "1.25rem"
                    }}>{pageinfo.subtitulo}</h3>
                </div>
            </div>


            {/* Contenido de la paginas */}
            <section className='px-3'>
                <div className="container pb-5 pt-4">

                    <div className="row">

                        <div className="col-12 col-md-6 text-justify pb-5">
                            <motion.div
                                layout
                                initial={{ y: 20, opacity: 0 }}
                                whileInView={{ y: 0, opacity: 1 }}
                                transition={{
                                    ease: "linear",
                                    duration: 0.2,
                                    delay: 0.15 + (3 * 0.05)
                                }}>
                                <p dangerouslySetInnerHTML={{ __html: pageinfo.informacion }}></p>
                                <img src={pageinfo['imagen']} className="img-fluid shadow-sm rounded-3" alt="" />
                            </motion.div>
                        </div>

                        <div className="col-12 col-md-6 ">

                            <motion.div
                                layout
                                initial={{ y: 20, opacity: 0 }}
                                whileInView={{ y: 0, opacity: 1 }}
                                transition={{
                                    ease: "linear",
                                    duration: 0.2,
                                    delay: 0.15 + (3 * 0.05)
                                }}>
                                <p className='text-p fw-bold'>
                                    <i className="bi bi-send-fill me-2"></i>
                                    {pageinfo.formulario_titulo}
                                </p>
                            </motion.div>

                            <div className='text-center'>

                                <motion.div
                                    layout
                                    initial={{ y: 20, opacity: 0 }}
                                    whileInView={{ y: 0, opacity: 1 }}
                                    transition={{
                                        ease: "linear",
                                        duration: 0.2,
                                        delay: 0.15 + (3 * 0.05)
                                    }}>
                                    <p>Déjanos tu información aquí.</p>
                                </motion.div>

                            </div>
                            <form className="px-4" ref={formRef} id="formContacto" onSubmit={handleSubmit}>


                                <div className="row">
                                    <div className='mb-3 '>
                                        <motion.div
                                            layout
                                            initial={{ y: 20, opacity: 0 }}
                                            whileInView={{ y: 0, opacity: 1 }}
                                            transition={{
                                                ease: "linear",
                                                duration: 0.2,
                                                delay: 0.15 + (4 * 0.05)
                                            }}>
                                            {/* Nombred completo del paciente */}
                                            <label style={{ color: "rgb(000, 078, 89)" }} className='pb-2'>Nombre completo*</label>
                                            <input
                                                type="text"
                                                className='form-control rounded-0'
                                                placeholder='Nombre y apellidos'
                                                name="nombre"
                                                value={formData.nombre}
                                                required
                                                onChange={handleInputChange}
                                                style={{ height: '50px' }}
                                            />
                                        </motion.div>
                                    </div>
                                    <div className='mb-3 '>
                                        {/* Telefono */}
                                        <motion.div
                                            layout
                                            initial={{ y: 20, opacity: 0 }}
                                            whileInView={{ y: 0, opacity: 1 }}
                                            transition={{
                                                ease: "linear",
                                                duration: 0.2,
                                                delay: 0.15 + (5 * 0.05)
                                            }}>
                                            <label style={{ color: "rgb(000, 078, 89)" }} className='pb-2'>Número de teléfono de contacto*</label>
                                            <input
                                                type="number"
                                                className='form-control rounded-0'
                                                placeholder='Contacto vía WhatsApp'
                                                name="telefono"
                                                value={formData.telefono}
                                                required
                                                onChange={handleInputChange}
                                                style={{ height: '50px' }}
                                            />
                                        </motion.div>
                                    </div>
                                    <div className='mb-3'>
                                        {/* Email */}
                                        <motion.div
                                            layout
                                            initial={{ y: 20, opacity: 0 }}
                                            whileInView={{ y: 0, opacity: 1 }}
                                            transition={{
                                                ease: "linear",
                                                duration: 0.2,
                                                delay: 0.15 + (6 * 0.05)
                                            }}>
                                            <label style={{ color: "rgb(000, 078, 89)" }} className='pb-2'>Correo Electronico de contacto*</label>
                                            <input
                                                type="email"
                                                className='form-control rounded-0'
                                                placeholder='Correo'
                                                name="email"
                                                value={formData.email}
                                                required
                                                onChange={handleInputChange}
                                                style={{ height: '50px' }}
                                            />
                                        </motion.div>
                                    </div>
                                </div>

                                <div className='text-end'>
                                    <button
                                        type='submit'
                                        className='btn btn-primary fw-bold p-3'
                                        style={{
                                            fontSize: "13px",
                                            letterSpacing: "1px"
                                        }}
                                    >
                                        ENVIAR
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}


export default Landings_areas